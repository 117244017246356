import { Button } from "../../Components/Common/Button/Button";
import Header from "../../Components/Common/Header";
import { Carousel } from "../../Components/Common/Corousel/Carousel";
import introVideo from '../../video.mp4'
import styles from './home.module.css';
import buttonIcon from './buttonIcon.png';
import desk2 from './desk_2.webp';
import desk3 from './desk_3.webp';
import desk4 from './desk_4.webp';
import Features from "../../Components/Home/Features";
import Investors from "../../Components/Home/Investors";
import Reviews from "../../Components/Home/Reviews";
import Media from "../../Components/Home/Media";
import Footer from "../../Components/Home/Footer";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getCookie } from "../../modules/cookie";
import { CarouselDesktop } from "../../Components/Common/CorouselDesktop/CarouselDesktop";
import { HomePageMetaPixel, iOS } from "../../common/utils";
import { useEffect, useRef, useState } from "react";
import Play from "./PlayIcon";
import { PWAInstallButton } from "../../Components/Common/PWAButton";

const NewHome = () => {
  const navigate = useNavigate();
  const [scrolled, setScrolled] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [searchParams, _setSearchParams] = useSearchParams();
  const isUpdateFlow = searchParams.get('update');
  const [isPaused, setIsPaused] = useState(true);
  const videoRef = useRef(null);
  const scrollHandler = (e) => {
    if (window.scrollY > 250 && !scrolled) {
      setScrolled(true)
    } else if (window.scrollY <= 250) {
      setScrolled(false)
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', scrollHandler);

    return () => {
      window.removeEventListener('scroll', scrollHandler);
    }
  }, [])

  const navigateToDownloadApk = () => {
    navigate("/download-app");
  }
  const toggleVideoState = () => {
    if (videoRef.current.paused) {
      videoRef.current.play()
    } else {
      videoRef.current.pause()

    }
  }
  return (
    <div className={styles.homeContainer}>
      <HomePageMetaPixel />
      <div id='app' className={styles.carouselWithHeader}>
        <Header forceActive />
        <div className={styles.desktopCarousel}>
          <CarouselDesktop showDots={false} use={'home'} images={[desk3, desk4, desk2]} />
        </div>
        <div className={styles.appPitch}>
          {/* AppPitch */}
          <div className={styles.appPitchDetails}>
            <PWAInstallButton>
            <img className={styles.appPitchIcon} alt='app-icon' src="https://d1jns1sy0m2jd7.cloudfront.net/frnd_media/post_media/logo_4x.webp" />
              <h3 className={styles.appPitchName}>FRND: Talk to Friends Online </h3>
              <p className={styles.appPitchDescription}>India’s Safest Friendship App</p>
            </PWAInstallButton>
          </div>
          <div className={styles.appPitchStats}>
            <div className={styles.appPitchRating}>
              <div className={styles.appPitchRatingTitleContainer}>
                <h3 className={styles.appPitchRatingTitle}>4.4</h3>
                <span className={styles.appPitchRatingTitleStar}>★</span>
              </div>
              <p className={styles.appPitchRatingSubtitle}>143K reviews</p>
            </div>
            <div className={styles.appPitchStatsDivider} />
            <div className={styles.appPitchDownload}>
              <h3 className={styles.appPitchDownloadTitle}>10M+</h3>
              <p className={styles.appPitchDownloadSubtitle}>Downloads</p>
            </div>
            <div className={styles.appPitchStatsDivider} />
            <div className={styles.appPitchAge}>
              <h3 className={styles.appPitchAgeTitle}>12+</h3>
              <p className={styles.appPitchAgeSubtitle}>Rated for 12+</p>
            </div>
          </div>
          {iOS() ? <Button shimmer={false} onPress={() => {
            const accessToken = getCookie('access_token');
            if (accessToken) {
              navigate('/packages');
            } else {
              navigate('/login')
            }
          }} className={styles.appPitchButton} buttonText={"RECHARGE COINS"} backgroundColor={"#4AA9FF"} leftImage={buttonIcon} /> : <Button onPress={navigateToDownloadApk} className={styles.appPitchButton} shimmer buttonText={isUpdateFlow ? "UPDATE NOW" : "DOWNLOAD NOW"} backgroundColor={"#009A66"} />}
          <div className={styles.pitchCarousel}>
            <div style={{ position: 'relative' }}>

              <video ref={videoRef} onPause={() => setIsPaused(true)} onPlay={() => setIsPaused(false)} onClick={toggleVideoState} controls={false} autoPlay={false} disablePictureInPicture playsInline controlsList="nodownload noplaybackrate noremoteplayback" className={styles.pitchCarouselElement}>
                <source src={introVideo} />
              </video>
              {isPaused ? <div onClick={toggleVideoState} style={{ height: 32, width: 32, position: 'absolute', bottom: '50%', left: '50%', backgroundColor: '#0009', borderRadius: 100, transform: 'translate(-50%, 50%)', alignItems: 'center', justifyContent: 'center', display: 'flex' }}><Play onPlayerClick={toggleVideoState} style={{ width: 12, height: 12, transform: 'translateX(2px)' }} /></div> : null}
              {<img onClick={() => videoRef.current.requestFullscreen()} alt="" src={"https://static.vecteezy.com/system/resources/previews/009/391/846/non_2x/full-screen-icon-sign-design-free-png.png"} style={{ position: 'absolute', bottom: 16, right: 16, width: 12, height: 12, filter: 'invert(100%)' }} />}
            </div>
            <img className={styles.pitchCarouselElement} src="https://i.ibb.co/7tsfbbV/front-screen.png" />
            <img className={styles.pitchCarouselElement} src="https://d1jns1sy0m2jd7.cloudfront.net/frnd_media/post_media/2_4x.webp" />
            <img className={styles.pitchCarouselElement} src="https://d1jns1sy0m2jd7.cloudfront.net/frnd_media/post_media/3_4x.webp" />
          </div>
        </div>
        {!iOS() && <div style={window.innerWidth > 600 ? {} : { transform: scrolled ? 'translateY(0px)' : 'translateY(100px)' }} className={styles.buttonsContainer}>
          <div className={`${styles.buttonContainer} ${styles.appRechargeButton}`}>
            <Button shimmer={false} onPress={() => {
              const accessToken = getCookie('access_token');
              if (accessToken) {
                navigate('/packages');
              } else {
                navigate('/login')
              }
            }} buttonText={"RECHARGE COINS"} backgroundColor={"#4AA9FF"} leftImage={buttonIcon} />
          </div>
          <div className={`${styles.buttonContainer} ${styles.downloadAppButton}`}>
            <Button onPress={navigateToDownloadApk} shimmer buttonText={isUpdateFlow ? "UPDATE NOW" : "DOWNLOAD NOW"} backgroundColor={"#009A66"} />
          </div>
        </div>}
      </div>
      <Features />
      <Reviews />
      <Investors />
      <Media />
      <Footer />



    </div>
  )
}


export default NewHome;