import React, { useEffect, useRef } from 'react'

export const PWAInstallButton = ({children, className}) => {
  const deferredPrompt = useRef(null)

  useEffect(() => {
    const handlePrompt = (e) => {
      // Prevent the mini-infobar from appearing on mobile
      e.preventDefault()
      // Stash the event so it can be triggered later.
      deferredPrompt.current = e
    }

    window.addEventListener('beforeinstallprompt', handlePrompt)
    return () => window.removeEventListener('beforeinstallprompt', handlePrompt)
  })

  const installPWA = () => {
    if (!deferredPrompt.current) return
    deferredPrompt.current.prompt()
    // Wait for the user to respond to the prompt
    deferredPrompt.current.userChoice.then((choiceResult) => {
      if (choiceResult.outcome === 'accepted') {
        localStorage.setItem("pwa-is-installed", 'true')
      }
    })
  }
  return (
    <div
      onClick={installPWA}
      className={className}
    >
      {children}
    </div>
  )
}